import React from "react"
import { Router } from "@reach/router"
import App from "../components/app"
import Layout from "../layout/layout"

const Cafes = (props) => {
  return (
    <Layout>
      <Router>
        <App path="/cafe/:searchString" />
      </Router>
    </Layout>
  )
}

export default Cafes
